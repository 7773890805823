<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="9" width="6" height="2" fill="currentColor" />
    </svg>
  </i>
</template>

<style scoped lang="scss">
i {
  width: 20px;
  height: 20px;
  display: inline-block;
}
</style>
